import React, {useContext} from 'react';
import powerhouse_logo_full from './imgs/powerhouse_logo_full.png'
import powerhouse_logo_no_text from './imgs/powerhouse_logo_no_text.jpg'
import powersearch from './imgs/powersearch.png'
import {initialSearchContext, SearchContext} from "../pages/searchv2";

export function LogoLarge(props) {
    return (
        <img src={powerhouse_logo_full} className={props.cname} alt="Large Powerhouse logo"/>
    );
}

export function PowersearchLogo(props) {
    return (
        <img src={powersearch} className={props.cname} alt="Powersearch logo"/>
    );
}

export function LogoNoText(props) {
    const {searchOptions, setSearchOptions} = useContext(SearchContext)
    const handleClick = () => {
        setSearchOptions(initialSearchContext)
    }
    return (

        <img style={{cursor:"pointer"}}src={powerhouse_logo_no_text} className={props.cname} alt="Powerhouse Logo with no text" onClick={handleClick}/>
    );
}
