import React, {useContext, useState} from 'react';
import { TextField, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList';
import Tooltip from '@material-ui/core/Tooltip';
import FilterBox from './filters'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';

import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import {SearchContext} from "../pages/searchv2";
import SearchInstuctionsModal from "./searchInstructionsModal";
import ReactGA from 'react-ga4'
import { useAuth0 } from '@auth0/auth0-react';


const useStyles = makeStyles({
    searchbar: {
        width: "100%",
    },
    inline: {
       justifyContent: "flex-end",
       margin: '5px'
    }
});

const TrackGoogleAnalyticsEvent = (
    category,
    event_name,
    label,
    data
) => {

    let event_params = {
        category,
        label,
        ...data
    };
    console.log('GA event params ', event_params)
    // Send GA4 Event
    ReactGA.event(event_name, event_params);
};


export default function Searchbar(props) {
    const {searchOptions, setSearchOptions} = useContext(SearchContext)
    const [query, setQuery] = useState(searchOptions.searchPhrase)
    const { user } = useAuth0() 
    const classes = useStyles()


    const handleSubmit = (event) => {
        event.preventDefault();
        TrackGoogleAnalyticsEvent(
            "query",
            "search",
            "search_query",
            { query: query, name: user['name'] }
        )
        setSearchOptions({...searchOptions, searchPhrase: query, allResults: 0})
    }
    const handleSubmitAllResults = (event) => {
      event.preventDefault();
      TrackGoogleAnalyticsEvent(
            "all",
            "search",
            "search_query",
            { query: "", name: user['name'] }
        )
      setSearchOptions({...searchOptions, searchPhrase: " ", allResults: 1})
        setQuery(" ")
    }
    const [filterBoxOpen, setFilterBoxOpen] = React.useState(false);

    const handleBoxOpen = () => setFilterBoxOpen(true);
    const handleBoxClose = () => setFilterBoxOpen(false);

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (filterBoxOpen) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [filterBoxOpen]);

    return (
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <FilterBox
                        open={filterBoxOpen}
                        scroll="paper"
                        handleBoxClose={handleBoxClose}
                    />

                    <div className="TextField-without-border-radius">
                        <TextField className={classes.searchbar}
                                   onChange={(event) => setQuery(event.target.value)}
                                   fullWidth={true}
                                   id="main-search"
                                   label="Search"
                                   color="primary"
                                   variant="outlined"
                                   defaultValue={searchOptions.searchPhrase}
                                   value={query}
                                   InputProps={{
                                       startAdornment: (
                                           <InputAdornment>
                                               <IconButton onClick={handleSubmit}>
                                                   <SearchIcon />
                                               </IconButton>
                                           </InputAdornment>),
                                       endAdornment: (

                                           <Grid container justify="flex-end">
                                               <Box m={1}>
                                                   <Chip label="Show All Startups" onClick={handleSubmitAllResults}/>
                                               </Box>
                                               <Tooltip title = "Filter By" placement="top">
                                                   <IconButton aria-label="filter"
                                                               className = {classes.filterBox}
                                                               onClick = {handleBoxOpen}
                                                               aria-expanded={filterBoxOpen}

                                                   >
                                                       <FilterListIcon />
                                                   </IconButton>
                                               </Tooltip>
                                           </Grid>
                                       )
                                   }}
                        />
                    </div>
                </form>

    )
}
