import React, {useContext, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { DialogContent, DialogTitle } from '@material-ui/core';
import Comment from './comment';

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import Chip from '@material-ui/core/Chip';
import { redirectLink } from '../utils';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import PartnerScouting from './partnerScouting';
import {SearchContext} from "../pages/searchv2";
import {useAuth0} from "@auth0/auth0-react";





const useStyles = makeStyles({
    root: {
        minWidth: "50%",
        minHeight: "50%",
    },
    commentBoxRoot: {
        width: "100%",
        margin: 'auto',
    },
    commentBox: {
        width: '100%',
    },
    noCommentsMsg: {
        fontStyle: 'italic',
    },
    progressRoot: {
        display: 'flex',
        justifyContent: 'center',
    },
    relatedStartup: {
        margin: '2px',
        fontStyle: 'italic',
    },
    subtitle: {
        fontWeight: 'bold',
    }
})

// Function below is to convert numbers to currency e.g 15000000 = 15M
function nFormatter(num) {
     if (num === 'None') {
       return num
     }
     var num_int = parseInt(num)
     if (num_int >= 1000000) {
        return (num_int / 1000000) + 'M';
     }
     if (num >= 1000) {
        return (num_int / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
     }
     return num_int;
}

export default function InformationModal(props) {
    const { open, scroll, handleModalClose, comments, startupID, neighbors, pscouting, info, competitors} = props;
    const {searchOptions, setSearchOptions} = useContext(SearchContext)
    const { user, isAuthenticated} = useAuth0();
    const isExternalUser = user['/roles'].includes("External")

    const {
      phPerspective,
      hardwareSoftware,
      customerType,
      fundingTotal,
      additionalFocus,
      headquarters,
      diversitySpotlight,
      numCalls,
      numEmails,
      numIntros,
      leadSource,
      intakeMethod,
    } = info

    const classes = useStyles()
    return (
        <Dialog
            open={open}
            scroll={scroll}
            onClose={handleModalClose}
            aria-labelledby="information-modal"
            aria-describedby="more-information-modal"
            className={classes.root}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle>
                <Typography variant="h6">
                    More Information
                </Typography>
            </DialogTitle>

            <DialogContent>
              <Typography variant="body1" className={classes.subtitle}>
                    Hardware / Software
              </Typography>
              { hardwareSoftware !== "None" &&
              <Typography variant="body2" display="inline">
                {hardwareSoftware}
              </Typography>
              }

              { hardwareSoftware === "None" &&
              <Typography variant="body2" display="inline">
                None provided.
              </Typography>

              }
              <Typography> <br /> </Typography>
              <Typography variant="body1" className={classes.subtitle}>
                    Customer Type
              </Typography>
              <Grid container>
                {customerType.length !== 0 && customerType.map((c, i) => {
                  return (
                      <Box m={.5}>
                          <Chip
                              variant='outline'
                              key={i}
                              label={c}
                              size="small"
                              onClick={() => setSearchOptions({...searchOptions, searchPhrase:c})}
                              />
                      </Box>
                            );
              })}
              { customerType.length === 0 &&
                  <Typography variant="body2">
                      No customer type found.
                      </Typography>
              }
              </Grid>
              <Typography> <br /> </Typography>
              <Typography variant="body1" className={classes.subtitle}>
                    Powerhouse Perspective
              </Typography>

              { phPerspective !== "" &&
              <Typography variant="body2" display="inline">
                {phPerspective}
              </Typography>
              }

              { phPerspective === "" &&
              <Typography variant="body2" display="inline">
                None provided.
              </Typography>
              }
              <Typography> <br /> </Typography>
              <Typography variant="body1" className={classes.subtitle}>
                    Diversity Spotlight
              </Typography>
              <Grid container>
                {diversitySpotlight.length !== 0 && diversitySpotlight.map((c, i) => {
                  return (
                      <Box m={.5}>
                          <Chip
                              variant='outline'
                              key={i}
                              label={c}
                              size="small"
                              />
                        </Box>
                            );
              }) }
              { diversitySpotlight.length === 0 &&
                  <Typography variant="body2">
                      No diversity spotlight found.
                      </Typography>
              }
              </Grid>
                <Typography> <br /> </Typography>
              <Divider />
              <Typography variant="body1" className={classes.subtitle}>
                    Additional Information
              </Typography>
              <Typography variant="body2" display="inline">
                <Box fontWeight="fontWeightMedium">
                    Headquarters: {headquarters} <br />
                    Funding Total: {nFormatter(fundingTotal)} <br />
                    Additional Focus: {additionalFocus} <br />
                    Number of Emails: {numEmails} <br />
                    Number of Calls: {numCalls}   <br />
                    Number of Introductions: {numIntros} <br />
                </Box>
              </Typography>
              {!isExternalUser &&
                <Typography variant="body2" display="inline">
                  <Box fontWeight="fontWeightMedium">
                      Lead Source: {leadSource}<br />
                      Intake Method: {intakeMethod}<br />
                  </Box>
                </Typography>

                }
              <Typography> <br /> </Typography>


              {!isExternalUser &&
                <Divider />
              }
              {!isExternalUser &&
                <Typography variant="body1" className={classes.subtitle}>
                      Partner Scouting
                </Typography>
              }

              <Grid container>
              {!isExternalUser &&
                <PartnerScouting
                    scouts={pscouting}
                    startupID={startupID}
                   />
                  }
              </Grid>

                <Typography> <br /> </Typography>
                <Divider />
                <Typography variant="body1" className={classes.subtitle}>
                    Related Startups
                    </Typography>

                  {neighbors.map((n, i) => {
                      return (
                          <div key={i}>
                              <Chip
                                  color='secondary'
                                  size='small'
                                  variant='outline'
                                  label={n.name}
                                  onClick={() => setSearchOptions({...searchOptions, searchPhrase:n.name})}
                              />
                              <Link href={n.website} onClick={redirectLink(n.website)}>
                                  <IconButton
                                      color="primary"
                                      size="small"
                                      aria-label="Link to company website.">
                                      <LinkIcon></LinkIcon>
                                  </IconButton>
                              </Link>
                          </div>
                      )
                  })}
                <Divider />
                <Typography variant="body1" className={classes.subtitle}>
                    Competitors
                </Typography>

                {competitors.map((n, i) => {
                    return (
                        <div key={i}>
                            <Chip
                                color='secondary'
                                size='small'
                                variant='outline'
                                label={n}
                                onClick={() => setSearchOptions({...searchOptions, searchPhrase:n})}
                            />
                        </div>
                    )
                })}
                {!isExternalUser &&
                <Typography> <br /> </Typography>
                }
                {!isExternalUser &&
                <Divider />
                }
                { !isExternalUser &&

                <Typography variant="body1" className={classes.subtitle}>
                    Comments
                </Typography>
                }
                {!isExternalUser && comments.map((comment, i) => {
                    return <Comment
                        message={comment.message}
                        user={comment.userID}
                        timestamp={comment.timestamp}
                        key={i}
                         />
                })}
                {(!isExternalUser && comments.length === 0) &&
                    <Typography
                        variant='body1'
                    >
                        No comments so far. Be the first!
                    </Typography>}

            <DialogActions>
                <Button onClick={handleModalClose} color="secondary">
                    Cancel
            </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

    );
}
