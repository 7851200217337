import React, {useContext, useState} from 'react';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button'
import { domain } from '../constants';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import {useQuery} from "react-query";
import {getPartnerScouting} from "../apis/powersearch";
import {SearchContext} from "../pages/searchv2";


const useStyles = makeStyles({
    root: {
        marginLeft: 'auto',
        alignText: 'right',
    },
    chip: {
        margin: '3px'
    },
})

function PartnerScout(props) {
    const { label, disabled, selected, setSelected, id } = props;
    const classes = useStyles()
    return (
        <Chip
            label={label}
            variant={selected ? 'default' : 'outline'}
            disabled={disabled}
            onClick={() => setSelected(id)}
            className={classes.chip}
            color="primary"
        />
    )
}

export default function PartnerScouting(props) {
    const {startupID} = props
    const [openSelectScout, setOpenSelectScout] = useState(false)
    const [scoutSelection, setScoutSelection] = useState([])
    const [currentScouts, setCurrentScouts] = useState(props.scouts ? props.scouts : [])
    const [sendingApiCall, setSending] = useState(false)
    const{data: scoutMapping} = useQuery("scoutMapping", getPartnerScouting)
    const {searchOptions, setSearchOptions} = useContext(SearchContext)

    React.useEffect(() => {
        setCurrentScouts(props.scouts ? props.scouts : [])
    }, [props.scouts])

    const selectScout = (id) => {
        if (scoutSelection.includes(id)) {
            var newSelection = [...scoutSelection]
            newSelection.splice(newSelection.indexOf(id));
            setScoutSelection(newSelection)
        }
        else {
            setScoutSelection([id, ...scoutSelection])
        }
    }

    const handleSelectClick = () => {
        setOpenSelectScout(!openSelectScout);
    }
    const handleCloseSelect = () => {
        setOpenSelectScout(!openSelectScout);
    }

    const handleCloseSubmit = () => {
        setSending(true);
        fetch(domain + '/api/addpartnerscout/' + startupID, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ partnerScouting: scoutSelection })
        }).then(resp => {
            if (!resp.ok) {
                console.log(domain + '/api/addpartnerscout/' + startupID, resp)
            }
            return resp;
        })
            .then(resp => resp.json())
            .then(resp => setCurrentScouts(resp.partnerScouting))
            .then(() => {
                setScoutSelection([]);
                setOpenSelectScout(false);
                setSending(false);
            })
    }

    const classes = useStyles()
    return (
      <Grid container direction="row" alignItems="baseline" justifyContent="flex-start" className={classes.root}>
            <Dialog
                open={openSelectScout}
                onClose={handleCloseSelect}
                scroll="paper"
                aria-labelledby="Partner Scouting Select"
                aria-describedby="Select which partner scouts to add"
            >
                <DialogTitle>Select Additional Partner Scouting Values</DialogTitle>
                <DialogContent dividers={true}>
                    {scoutMapping.map((option, i) => {
                        return (
                            <PartnerScout
                                key={i}
                                id={option[0]}
                                label={option[1]}
                                setSelected={selectScout}
                                selected={scoutSelection.includes(option[0])}
                                disabled={currentScouts.includes(option[1])}
                            />)
                    })}
                    {sendingApiCall && <LinearProgress color="secondary" />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSelect} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCloseSubmit} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={1} direction="row" alignItems="baseline" justifyContent="flex-end">
                <Tooltip title='Add Partner Scouting' placement='right'>
                    <IconButton
                        onClick={handleSelectClick}
                        aria-label="Open Selection for Partner Scouting">
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </Grid>

            {currentScouts.map((s, i) => {
                return (
                    <Box>
                        <Chip
                            variant='outlined'
                            key={i}
                            label={s}
                            className={classes.chip}
                            onClick={() => setSearchOptions({...searchOptions, searchPhrase: s})} />
                    </Box>)
            })}


        </Grid>
    )
}
