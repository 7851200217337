//mapping of streak api key to user name
import EK from './components/imgs/EK.png';
import AH from './components/imgs/AH.jpg';
import EM from './components/imgs/EM.jpg';
import EW from './components/imgs/EW.jpg';
import OS from './components/imgs/OS.jpg';
import RG from './components/imgs/RG.jpg';
import RS from './components/imgs/RS.jpg';
import MT from './components/imgs/MT.jpg';
import SC from './components/imgs/SC.jpeg';
import SHC from './components/imgs/SHC.jpeg';
export const team = {
    "agxzfm1haWxmb29nYWVyMwsSDE9yZ2FuaXphdGlvbiIQcG93ZXJob3VzZS5zb2xhcgwLEgRVc2VyGICAgICA5JEKDA":
        {
            "name": "Emily K",
            "img": EK
        },
    "agxzfm1haWxmb29nYWVyMwsSDE9yZ2FuaXphdGlvbiIQcG93ZXJob3VzZS5zb2xhcgwLEgRVc2VyGICAgIDglYMKDA":
         {
             "name": "Alexandra H",
            "img": AH
         },
    "agxzfm1haWxmb29nYWVyMwsSDE9yZ2FuaXphdGlvbiIQcG93ZXJob3VzZS5zb2xhcgwLEgRVc2VyGICAgICgkp8KDA":
        {
            "name" : "Marie T",
            "img": MT
        },
    "agxzfm1haWxmb29nYWVyMwsSDE9yZ2FuaXphdGlvbiIQcG93ZXJob3VzZS5zb2xhcgwLEgRVc2VyGICAgJj2s4AKDA":
        {
            "name" : "Ry S",
            "img" : RS
        },
    "agxzfm1haWxmb29nYWVyMwsSDE9yZ2FuaXphdGlvbiIQcG93ZXJob3VzZS5zb2xhcgwLEgRVc2VyGICAgq-Jr-gJDA":
        {
            "name" : "Rohit G",
            "img" : RG
        },
    "agxzfm1haWxmb29nYWVyMwsSDE9yZ2FuaXphdGlvbiIQcG93ZXJob3VzZS5zb2xhcgwLEgRVc2VyGICA0pnboo0KDA":
        {
            "name" : "Emma M",
            "img" : EM
        },
    "agxzfm1haWxmb29nYWVyMwsSDE9yZ2FuaXphdGlvbiIQcG93ZXJob3VzZS5zb2xhcgwLEgRVc2VyGICA0uPk27gIDA":
        {
            "name": "Ocatvi S",
            "img": OS
        },
    "agxzfm1haWxmb29nYWVyMwsSDE9yZ2FuaXphdGlvbiIQcG93ZXJob3VzZS5zb2xhcgwLEgRVc2VyGICAspOlxesLDA":
        {
            "name" : "Ella W",
            "img" : EW
        },
    "agxzfm1haWxmb29nYWVyMwsSDE9yZ2FuaXphdGlvbiIQcG93ZXJob3VzZS5zb2xhcgwLEgRVc2VyGICAwvuHobcJDA":
        {
            "name": "Abe B",
            "img" : ""
        },
    "agxzfm1haWxmb29nYWVyMwsSDE9yZ2FuaXphdGlvbiIQcG93ZXJob3VzZS5zb2xhcgwLEgRVc2VyGICA8rLS_qUJDA":
        {
            "name" : "Liam M",
            "img" : ""
        },
    "agxzfm1haWxmb29nYWVyMwsSDE9yZ2FuaXphdGlvbiIQcG93ZXJob3VzZS5zb2xhcgwLEgRVc2VyGICA8tGVip0KDA":
        {
            "name" : "Nithin C",
            "img" : ""
        },
    "agxzfm1haWxmb29nYWVyMwsSDE9yZ2FuaXphdGlvbiIQcG93ZXJob3VzZS5zb2xhcgwLEgRVc2VyGICA8q_km6sKDA":
        {
            "name" : "Cheri T",
            "img" : ""
        }
}
