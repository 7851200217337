export const redirectLink = (website) => {
    return (e) => {
        e.preventDefault();
        window.open(website, "_blank", "noopener noreferrer");
    }
}

export const qualityToStyle = (quality) => {
    const ret = {}
    if (quality === 'Full recommendations (great)' || quality === 'Some recommendations (good)' || quality === 'Initial impression: great') {
        ret.backgroundColor = '#949494'
    }
    if (quality === 'Limited recommendations (some concerns)' || quality === 'Initial impression: some concerns') {
        ret.backgroundColor = '#949494'
    }
    if (quality === 'No recommendations (poor)' || quality === 'Initial impression: poor') {
        ret.backgroundColor = '#949494'
    }
    if (quality === 'Prioritized Lead') {
        ret.backgroundColor = '#949494'
    }
    return ret
}

export function tag2color(tag) {
    let hash = 0;
    for (let i = 0; i < tag.length; i++) {
        hash = tag.charCodeAt(i) + ((hash << 5) - hash);
    }

    var colors = {
      0: "#687090",
      1: "#DF1864",
      2: "#FFCD05",
      3: "#A770A0",
      4: "#93278F",
      5: "#70CADC",
      6: "#3EB891",
      7: "#079797",
      8: "#4D4D4D",
      9: "#949494"
    };

    return colors[Math.abs(hash % 10)];
}