import React, { useState, useEffect } from 'react';
import ApplicationHeader from '../components/header'
import { ThemeProvider, makeStyles } from '@material-ui/core';
import theme from '../theme';
import SearchLanding from '../containers/search-landing2';
import SearchResults from '../containers/search-results2';
import { domain } from '../constants';
import {getFilters, getNumberOfStartups, getPartnerScouting, search} from "../apis/powersearch";
import { useQueryClient} from "react-query";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactGA from 'react-ga4';

const useStyles = makeStyles({
    root: {

    }
});

export const SearchContext = React.createContext();
export const initialSearchContext= {
    searchPhrase: "",
    filters: {
        focusTags: {type: false, values: []},
        customer: {type: false, values: []},
        diversity: {type: false, values: []},
        partner: {type: false, values: []},
        stage: {type: true, values: []},
        quality: {type: true, values: []},
        hardware: {type: true, values: []},
        funding: {type: true, values: []},
        thesis: {type: true, values: []},
    },
    searchCommentState: true,
    allResults: 0,
}
function SearchPageV2(props) {
  const classes = useStyles();
  const [searchOptions,setSearchOptions]= useState(initialSearchContext);
  const {searchPhrase,  searchCommentState, filters} = initialSearchContext;
  useQueryClient().prefetchQuery("scoutMapping", getPartnerScouting, {staleTime:Infinity});
  useQueryClient().prefetchQuery("filterOptions", getFilters, {staleTime:Infinity});
  useQueryClient().prefetchQuery("numTotalStartups", getNumberOfStartups, {staleTime:Infinity})
  useQueryClient().prefetchQuery(["search-results", " ", 1, searchCommentState,filters],()=> search(" ", 1,searchCommentState, filters))

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Home" });
  }, [])

  return (
    <ThemeProvider theme={theme}>
        <ApplicationHeader/>
        <br/>
        <br/>
        <br/>
        <br/>
        <SearchContext.Provider value={{searchOptions,setSearchOptions}}>
            {(searchOptions.searchPhrase.length === 0 && searchOptions.allResults === 0) &&
            <SearchLanding
              />}
        {(searchOptions.searchPhrase.length !== 0 || searchOptions.allResults === 1)  &&
            <SearchResults
                />}
        </SearchContext.Provider>

    </ThemeProvider>);

}

export default withAuthenticationRequired(SearchPageV2, {
    onRedirecting: () => <CircularProgress color="secondary" />
});
