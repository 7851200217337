import Typography from "@material-ui/core/Typography";
import React from "react";
import Theme from "../../theme";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {List, ListItem, ListItemText} from "@material-ui/core";

const useStyles= makeStyles((theme)=>({
    helpRoot: {
        width: '100%',
            maxWidth: 600,
    },
    typography: {
        padding: theme.spacing(2),
    }
}));
export default function FilterInstructions(props){
    const instructions= ["How to use filters:",
        "All individual fields will be searched together as AND (e.g filter startups that have Stage: Portfolio Company AND Funding Status: Seed).",
    "Each field on the left column will be filtered as OR (Any Of) so a filter for Stage: Portfolio Company, Engaged will look for startups that have Stage as Portfolio Company OR Engaged.",
    " Fields on the right column by default will be filtered as AND (All Of) so a search for Focus: Energy Generation, Solar will look for startups that have Focus as Energy Generation AND Solar. However, the toggle switch will allow to switch the filter to search as OR (Any Of)."]
    const theme = useTheme();
    const classes = useStyles()
        return(
            <div className={classes.helpRoot}>
                {instructions.map((instruction, index) => (
                <Typography key={index} className={classes.typography}>{instruction}</Typography>
                ))}

            </div>
        )
}

