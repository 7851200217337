import React from 'react';
import './App.css';
import 'fontsource-roboto';
import {QueryClient, QueryClientProvider, useQuery, useQueryClient} from 'react-query'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import {BrowserRouter, Route, Switch} from 'react-router-dom';

import SearchPageV2, {initialSearchContext} from './pages/searchv2';
import Dashboard from './pages/dashboard';
import NotAuthenticated from "./components/authentication/NotAuthenticated";
import CircularProgress from "@material-ui/core/CircularProgress";
import {ReactQueryDevtools} from "react-query/devtools";
import {getDash, search} from "./apis/powersearch";
import ReactGA from 'react-ga4'

const TRACKING_ID = "G-4HWFB8G2W9" // google analytics tracking id
ReactGA.initialize(TRACKING_ID)

const queryClient = new QueryClient();
function App() {
    queryClient.prefetchQuery(["search-results", " ", 1, true,initialSearchContext.filters],()=> search(" ", 1,true, initialSearchContext.filters), {staleTime: Infinity})
    queryClient.prefetchQuery("dash", getDash, {staleTime:Infinity})
    const {
        isLoading,
        isAuthenticated,
        error,
        user,
        loginWithRedirect,
        logout,
    } = useAuth0();
    if (isLoading) {
        return <CircularProgress color="secondary" />;
    }
    if (error) {
        return <NotAuthenticated/>;
    }else {
        return (
            <QueryClientProvider client={queryClient}>
                <div className="App">
                    <Switch>
                        <Route exact path="/" component={SearchPageV2}/>
                        <Route path="/dash" component={Dashboard}/>
                    </Switch>
                </div>
            </QueryClientProvider>

        );
    }
}


export default App;