import React, { useState } from 'react';
import ApplicationHeader from '../components/header'
import { ThemeProvider, makeStyles } from '@material-ui/core';
import theme from '../theme';
import { domain } from '../constants';
import Plot from 'react-plotly.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { Typography, Button } from '@material-ui/core';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';

import {useQuery} from "react-query";
import {getDash, updateVenturesDealflow} from "../apis/powersearch";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";


const useStyles = makeStyles({
    root: {

    },
    formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    }
    });

const useStyles2 = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 170,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },

}));

function Dashboard(props) {

  const classes = useStyles();
  const classes2 = useStyles2();
  const [dealflow, setDealflow] = useState({});
  const { user, isAuthenticated} = useAuth0();
  const [characteristics, setCharacteristics] = useState(undefined);
  const [inquiries, setInquiries] = useState({})


  const isExternalUser = user['/roles'].includes("External")

  const handleDealflow = (event) => {
    setDealflow(event.target.value);
  };

  const handleCharacteristics = (event) => {
    setCharacteristics(event.target.value)
  }
  const handleUpdateDealflow = () => {
        updateVenturesDealflow()
  }

  const handleInquiries = (event) => {
    setInquiries(event.target.value);
  }




  const {data, isLoading, isError} = useQuery("dash", getDash, {staleTime:Infinity})

    React.useEffect(() => {
          if (!isLoading && !isError) {
            setDealflow(data.dealflowProgressQ3);
            setCharacteristics(data.fundingStatus)
            setInquiries(data.inquiries_week)
          }
        }
  ,[data])
  return (
    <ThemeProvider theme={theme}>
        <ApplicationHeader
        />
        <div>
          <h1> Dashboard </h1>
        </div>
        {(data === undefined || isLoading) &&
            <Grid item xs={12}>
              <h3> Please give a few seconds to load... </h3>
                <CircularProgress color="secondary" />
            </Grid>}
        {data !==undefined && !isLoading && data.progress &&
          <Typography variant = "h5"> Database </Typography>
        }

        {data !==undefined && !isLoading && data.progress &&
          <Plot
          data={data.progress.data}
          layout={data.progress.layout}
          />
        }

        <Box>
          {data !== undefined && !isLoading && data.fundingStatus && data.hardwareSoftware && data.thesisSector && data.intakeMethod &&
            <FormControl className={classes.formControl}>
             <Select
               value={characteristics}
               onChange={handleCharacteristics}
               displayEmpty
               className={classes.selectEmpty}
               inputProps={{ 'aria-label': 'Without label' }}
             >

               <MenuItem value={data.fundingStatus}>Funding Status</MenuItem>
               <MenuItem value={data.hardwareSoftware}>Hardware / Software</MenuItem>
               <MenuItem value={data.thesisSector}>Thesis Sector</MenuItem>
               <MenuItem value={data.intakeMethod}>Intake Method</MenuItem>
             </Select>
             <FormHelperText>Select Field Type</FormHelperText>
           </FormControl>
          }

          {characteristics !==undefined && !isLoading &&
            <Plot
            data={characteristics.data}
            layout={characteristics.layout}
            />
          }
          </Box>

        {data !==undefined && !isLoading && data.geoBar &&
          <Plot
          data={data.geoBar.data}
          layout={data.geoBar.layout}
          />
        }

        {data !==undefined && !isLoading && data.hqMap &&
          <Plot
          data={data.hqMap.data}
          layout={data.hqMap.layout}
          />
        }

        {!isExternalUser && data !==undefined && !isLoading && data.venturesStages &&
          <Typography variant = "h5"> Ventures </Typography>
        }
        {!isExternalUser && data !==undefined && !isLoading && data.venturesStages &&
          <Plot
          data={data.venturesStages.data}
          layout={data.venturesStages.layout}
          />
        }

        {!isExternalUser && data !==undefined && !isLoading && data.bdFlow &&
          <Typography variant = "h5"> BD </Typography>
        }
        {!isExternalUser && data !==undefined && !isLoading && data.bdFlow &&
          <Plot
          data={data.bdFlow.data}
          layout={data.bdFlow.layout}
          />
        }
      {!isExternalUser && data !==undefined && !isLoading && data.inquiries_month && data.inquiries_week &&
          <Grid container direction={"row"} color={"red"} spacing={3} justify={"center"}>
            <Grid item >
                <Grid container direction={"column"} spacing={4} justify={"center"}>
                    <Grid item>
                        <FormControl className={classes.formControl}>
                            <Select
                                value={inquiries}
                                onChange={handleInquiries}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >

                                <MenuItem value={data.inquiries_week}>By Week</MenuItem>
                                <MenuItem value={data.inquiries_month}>By Month</MenuItem>
                            </Select>
                            <FormHelperText>Select Time Period</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Plot
                    data={inquiries.data}
                    layout={inquiries.layout}
                />
            </Grid>
          </Grid>
      }
        <Box>
          {!isExternalUser && data !== undefined && !isLoading && data.dealflowProgressQ1 && data.dealflowProgressQ2 && data.dealflowProgressQ3 &&
              <Grid container direction={"row"} color={"red"} spacing={3} justify={"center"}>
                  <Grid item >
                      <Grid container direction={"column"} spacing={4} justify={"center"}>
                          <Grid item>
                              <FormControl className={classes.formControl}>
                                  <Select
                                      value={dealflow}
                                      onChange={handleDealflow}
                                      displayEmpty
                                      className={classes.selectEmpty}
                                      inputProps={{ 'aria-label': 'Without label' }}
                                  >

                                      <MenuItem value={data.dealflowProgressQ1}>Q1</MenuItem>
                                      <MenuItem value={data.dealflowProgressQ2}>Q2</MenuItem>
                                      <MenuItem value={data.dealflowProgressQ3}>Q3</MenuItem>
                                  </Select>
                                  <FormHelperText>Select Quarter</FormHelperText>
                              </FormControl>
                          </Grid>
                          <Grid item>
                              <Button onClick={handleUpdateDealflow}  size={"large"} variant={"outlined"}>
                                  Update
                              </Button>
                          </Grid>
                      </Grid>
                  </Grid>
                  <Grid item>
                      <Plot
                          data={dealflow.data}
                          layout={dealflow.layout}
                      />
                  </Grid>


              </Grid>

          }


        </Box>


        {!isExternalUser && data !==undefined && !isLoading && data.startupsAdded &&
            <Plot
                data={data.startupsAdded.data}
                layout={data.startupsAdded.layout}
            />
        }
        {!isExternalUser && data !==undefined && !isLoading && data.introductions &&
          <Plot
            data={data.introductions.data}
            layout={data.introductions.layout}
          />
        }



    </ThemeProvider>
  )

}

export default withAuthenticationRequired(Dashboard, {
  onRedirecting: () => <CircularProgress color="secondary" />
});
