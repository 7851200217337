import {domain} from "../constants";

export const search=  (query, allResults, searchCommentsAndLogs, filters) =>{
    return fetch(domain + '/api/essearch/' + query +  '?showAll=' + allResults.toString() +'&searchCommentsLogs=' + searchCommentsAndLogs.toString() + '&filters=' + encodeURIComponent(JSON.stringify(filters))  ).then(resp => {
          if (!resp.ok) {
              console.log(domain + '/api/essearch/' + query, resp)
          }
          return resp
      },[query]).then(resp=>resp.json())
}

export const getPartnerScouting = () => {
    return fetch(domain + '/api/partnerscouting').then(resp => {
        if (!resp.ok) {
            console.log(domain + '/api/partnerscouting', resp)
        }
        return resp
    }).then(resp=>resp.json())
}

export const getFundraisingPlots = (currentStartups, filter) => {
    const params = (new URLSearchParams({"filter":filter})).toString()
    // debugger;
    return fetch(domain + '/api/plotfundraising?' +params, {method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },body:JSON.stringify({startupsToPlot: currentStartups})}).then(resp => {
        if (!resp.ok) {
            console.log(domain + '/api/plotfundraising', resp)
        }
        return resp
    }).then(resp=>resp.json())
}

export const getFundraisingCsv = (currentStartups) => {
    return fetch(domain + '/api/getfundraisingcsv' , {method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },body:JSON.stringify({startups: currentStartups})}).then(resp => {
        if (!resp.ok) {
            console.log(domain + '/api/getfundraisingcsv', resp)
        }
        // debugger;
        return resp
    }).then(resp=>resp.text())
}

export const updateVenturesDealflow= () => {
    const url = domain + '/api/runVenturesDealflowUpdate'
    return fetch(url).then(resp => {
        // debugger;
        if(!resp.ok){
            console.log(url, resp)
        }
        return resp
    }).then(resp=>resp.json())
}

export const updatePartnerScouting =(scoutSelection, startupID) => {
    return fetch(domain + '/api/addpartnerscout/' + startupID, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({partnerScouting: scoutSelection})
    }).then(resp => {
        if (!resp.ok) {
            console.log(domain + '/api/addpartnerscout/' + startupID, resp)
        }
        return resp;
    }).then(resp=>resp.json())

}
export const getNumberOfStartups = () => {
     return fetch(domain + '/api/getTotalStartups').then(resp => {
      if (!resp.ok) {
        console.log(domain + '/api/getTotalStartups', resp)
      }
      return resp
    }).then(resp=>resp.json())
}

export const getFilters = () => {
     return fetch(domain + '/api/getFilters').then(resp => {
          if (!resp.ok) {
              console.log(domain + '/api/getFilters', resp)
          }
          return resp
      }).then(resp=>resp.json())
}
export const getDash = ()=> {
    return fetch(domain + '/api/dash').then(resp => {
        if (!resp.ok) {
            console.log(domain + 'api/dash', resp)
        }
        return resp
    }).then(resp => resp.json())
}