import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Card, CardHeader, CardContent, CardMedia, CardActions, Grid, makeStyles} from "@material-ui/core";
import powerhouse_logo_no_text from '../imgs/powerhouse_logo_no_text.jpg'

const useStyles = makeStyles({
    grid: {
        minHeight: "90vh",
    },
    logo: {
        height: "50%",
        maxWidth: "20%",
        margin:"20px"
    }
});
export default function NotAuthenticated(){
    const { logout, user } = useAuth0();
    const classes = useStyles();
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >
        <Card sx={{ maxWidth: 345 }}>
            <CardMedia
                className={classes.logo}
                component="img"
                image={powerhouse_logo_no_text}
            />
            <CardHeader title={"Not Authorized to Access Powersearch"}>

            </CardHeader>
            <CardContent>
                You don't have authorization to access the app. Please contact Octavi if this is an error.
            </CardContent>

            <CardActions>
                <button onClick={() => logout({ returnTo: window.location.origin })}>
                    Logout
                </button>
            </CardActions>

        </Card>
        </Grid>
    );
}