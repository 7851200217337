import React from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import theme from '../theme';
import Grid from '@material-ui/core/Grid';
import AvatarSrc from '../components/avatarIcons';

const useStyles = makeStyles({
    root: {
        // background: 'grey',
        margin: '2px'
    },
    message: {
        textAlign: 'left',
        background: '#e4ebe8',
        padding: '4px',
        borderRadius: '8px'
    },
    body: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    date: {
        color: 'grey',
        textAlign: 'center',
        fontSize: '.3em',
    }
})

export default function Comment(props) {
    const { message, user, timestamp} = props
    const classes = useStyles()
    const userAvatar = AvatarSrc(user)
    return (
        < Grid container direction="row" justify="flex-start" alignItems="center" className={classes.body} >
            <Grid item xs={1} container direction="column" alignItems="flex-start">
                <Grid item xs={2}>
                    <Avatar src={userAvatar.img}>{userAvatar.name}</Avatar>
                </Grid>
            </Grid>
            <Grid item xs={10}>
                <Typography variant="body2" className={classes.message}>
                    {message}
                </Typography>
            </Grid>
        </Grid >
    )
}