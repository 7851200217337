import {Box, List, ListItem, ListItemText, Popover, Button, Chip} from "@material-ui/core";
import React, {useState} from "react";
export default function SearchInstuctionsModal(props) {
    const search_options= ["By default the search is run as an or operation, ex) Switch Maritime will be ran as Switch or Maritime",
        "+ signifies AND operation",
"| signifies OR operation",
"- negates a single token",
"\" wraps a number of tokens to signify a phrase for searching, ex) \"Switch Maritime\" searches for the whole phrase instead of individual words ",
"* at the end of a term signifies a prefix query"
]
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return(
        <div>
        <Box>
            <Chip
                label= "Search Instructions"
                onClick={handleClick}
            />
        </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List >
                    {search_options.map((search_option) => (
                        <ListItem>
                            <ListItemText primary={search_option} />
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </div>
    )
}



