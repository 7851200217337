import React, {useState, useContext} from 'react';
import Searchbar from '../components/searchbar2';
import { makeStyles, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import SearchResult from '../components/searchResult2';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LogoNoText } from '../components/logo';
import Pagination from '@material-ui/lab/Pagination';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FundingPlotsModal from '../components/fundingPlotsModal'

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useQuery} from "react-query";
import {getNumberOfStartups, search} from "../apis/powersearch";
import {SearchContext} from "../pages/searchv2";
import {useAuth0} from "@auth0/auth0-react";





function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
    root: {
        // minHeight: "80vh",
        minWidth: "90vw",
    },
    bg: {
        // backgroundColor: "red",
    },
    result: {
        marginLeft: "10%",
        marginRight: "10%",
    },
    searchBar: {
        marginTop: '2%',
        marginBottom: '2%',
        marginLeft: '2%',
        marginRight: '2%',
    },
    logo: {
        height: "50%",
        width: "50%",
        marginRight: "-70%",
    },
});

function sort_companies_last_updated_timestamp(data){
    // > 0 sort b before a, < 0 sort a before b, so want to be negative if -1 is first
    const sorted_time = data.sort((a,b) => a.lastUpdatedTimestamp > b.lastUpdatedTimestamp? -1 : 1);
    return sorted_time;

}

function sort_companies_last_stage_change_timestamp(data){
    // > 0 sort b before a, < 0 sort a before b, so want to be negative if -1 is first
    return data.sort((a,b) => a.lastStageChangeTimestamp > b.lastStageChangeTimestamp? -1 : 1);
}

function sort_companies(data) {
    if (!data) {
        return data;
    }
    const great = []
    const good = []
    const ok = []
    const poor = []
    const priority = []
    const misc = []
    for (let i = 0; i < data.length; i++) {
        let quality = data[i].quality
        switch (quality) {
            case 'Recommended with confidence':
                great.push(data[i]);
                break;
            case 'Recommended':
                good.push(data[i]);
                break;
            case 'Limited recommendations':
            case 'Initial impression: good':
                ok.push(data[i]);
                break;
            case 'No recommendations':
            case 'Initial impression: poor':
                poor.push(data[i]);
                break;
            case 'Prioritized Lead':
                priority.push(data[i]);
                break;
            default:
                misc.push(data[i])
                break;
        }

    }
    return great.concat(good, ok, priority, misc, poor);

}

const useStyles2 = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function SearchResults(props) {
    const classes = useStyles()
    const resultsPerPage = 25;
    const [resultsPage, setResultsPage] = useState(0)
    const [currentData, setCurrentData] = useState([])
    const [sort, setSort] = useState(1)
    const {data:totalResults} = useQuery("numTotalStartups", getNumberOfStartups,{staleTime:Infinity})
    const {searchOptions} = useContext(SearchContext)

    const { user, isAuthenticated} = useAuth0();
    const isExternalUser = user['/roles'].includes("External")
    const{searchPhrase, filters, searchCommentState, allResults} = searchOptions;
    const {data, isLoading} = useQuery(["search-results", searchPhrase, allResults, searchCommentState,filters],()=> search(searchPhrase, allResults,searchCommentState, filters),
        {enabled:allResults === 1 || searchPhrase!== '', staleTime:Infinity})
    React.useEffect(()=>{
        if (!isLoading && data !== undefined && data.length !== 0)
        {
            setSort(1);
            setCurrentData([...data]);
        }
    }, [data, isLoading])

    const classes2 = useStyles2();
    const handleChange = (event) => {
      if (event.target.value === 1) {
        setSort(1)
        setCurrentData(data);
      }
      if (event.target.value === 2) {
        setSort(2)
        setCurrentData(sort_companies([...data]));
      }
      if(event.target.value === 3){
          setSort(3)
          setCurrentData(sort_companies_last_updated_timestamp([...data]))
      }
      if(event.target.value === 4){
           setSort(4)
          setCurrentData(sort_companies_last_stage_change_timestamp([...data]))
      }
    };
    // debugger;
   //adding a key to the searchbar allows the searchbar to unmount when the searchPhrase context gets changed and reflect this change through the defaultValue param. T
    // This is used because we adjust the searchPhrase from other  files such as the info modal and we need to unmount and remount the searchbar to reflect the change
    return (
        <div style={{ overflow: 'scroll' }}>

            <Grid container
                className={classes.root}
                direction="row"
                justify="center"
                alignItems="center">
                <Grid item xs={1} className={classes.bg}>
                      <LogoNoText cname={classes.logo} />
                </Grid>
                <Grid item xs={4} className={classes.searchBar}>

                    <Searchbar
                        defaultValue={searchOptions.searchPhrase}
                        key={searchOptions.searchPhrase}
                    />
                </Grid>

                {(data === undefined || isLoading) &&
                    <Grid item xs={12}>
                        <CircularProgress color="secondary" />
                    </Grid>}


                {data !== undefined && data.length !== 0 && !isLoading &&

                  <div className="TextField-without-border-radius">
                    <FormControl variant="outlined" className={classes2.formControl}>
                      <InputLabel id="sort-by-button">Sort By</InputLabel>
                      <Select
                        labelId="sort-by-button"
                        id="sort-by-button"
                        onChange={handleChange}
                        defaultValue={1}
                        value={sort}
                        label="Sort By"
                      >
                        <MenuItem value={1}>Match Score</MenuItem>
                        <MenuItem value={2}>Quality Check</MenuItem>
                          <MenuItem value={3}>Last Updated</MenuItem>
                          <MenuItem value={4}>Last Stage Change</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                }


                <Snackbar open={data && data.length === 0} autoHideDuration={6000}>
                    <Alert severity="warning">
                        No startups found!
                    </Alert>
                </Snackbar>

                {data !== undefined && data.length !== 0 && !isLoading &&
                  <Box m={6}>
                    <Alert severity="info" variant="outlined" color="secondary">
                        {data.length} / {totalResults} startups found ({(data.length/totalResults * 100).toFixed()}%)
                    </Alert>
                  </Box>
                }
                {!isExternalUser &&
                    <FundingPlotsModal data={data}/>
                }



                {data !== undefined && data.length !== 0 && !isLoading && // Cards
                    < Grid item container xs={12} spacing={2}>
                        {currentData.slice(resultsPage * resultsPerPage, Math.min(data.length, resultsPerPage + resultsPage * resultsPerPage))
                            .map((company, i) => {
                                return (<Grid item xs={12}
                                    className={classes.result}
                                    key={i}>
                                    <SearchResult
                                        company={company}
                                        >
                                    </SearchResult>
                                </Grid>)
                            })}
                    </Grid>
                }

                {data !== undefined && data.length !== 0 && !isLoading && // Pagination

                    < Grid item container
                        direction="column"
                        justify="flex-end"
                        alignItems="center"
                        style={{ minHeight: '100%', display: 'flex', margin: '.5em' }}
                    >
                        <Grid item xs={12}>
                            <Pagination
                                count={Math.ceil(data.length / resultsPerPage)}
                                onChange={(e, v) => setResultsPage(v - 1)}
                                color="secondary"
                            />
                        </Grid>
                    </Grid>
                }
            </Grid >
        </div >

    )
}
export default SearchResults;
