import React, {useContext, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Searchbar from '../components/searchbar2';
import { makeStyles } from '@material-ui/core';
import { PowersearchLogo } from '../components/logo';
import SearchInstuctionsModal from "../components/searchInstructionsModal";
import {initialSearchContext, SearchContext} from "../pages/searchv2";


const useStyles = makeStyles({
    grid: {
        minHeight: "90vh",
    },
    logo: {
        height: "50%",
        width: "50%",
    }
});

export default function SearchLanding(props) {
    const classes = useStyles();
    return (
        <Grid container alignItems="center" justify="center" className={classes.grid}>
            <Grid item xs={12}>
                <PowersearchLogo cname={classes.logo}/>
            </Grid>
            <Grid item xs={3}/>
            <Grid item xs={6}>
                <Grid container direction={"column"}>
                    <Grid item>
                        <Searchbar />
                    </Grid>
                    <Grid item>
                        <SearchInstuctionsModal/>

                    </Grid>

                </Grid>

            </Grid>
            <Grid item xs={3}/>
            <Grid item xs={12}/>
            <Grid item xs={12}/>
        </Grid>
    );
}
