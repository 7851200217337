import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Typography, Button, IconButton, Icon, TextField, Dialog, DialogActions, Divider } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import ViewCompactOutlinedIcon from '@material-ui/icons/ViewCompactOutlined';
import Grid from '@material-ui/core/Grid'
import LinkIcon from '@material-ui/icons/Link';
import InformationModal from './informationModal2';
import Tooltip from '@material-ui/core/Tooltip';
import { redirectLink, tag2color, qualityToStyle } from '../utils';
import {SearchContext} from "../pages/searchv2";


const useStyles = makeStyles({
    header: {
        textAlign: "left",
        display: 'flex'
    },
    description: {
        textAlign: "left",
        paddingRight: "5%",
    },
    moreInfo: {
        marginLeft: 'auto',
    },
    thesisSector: {
        margin: '2px',
        top: '50%',
    },
    focusChip: {
        margin: '1px',
    },
    supplementaryInfo: {
        color: '#a6a6a6',
        textAlign: 'left',
        fontStyle: 'italic',
    },
})
const createStreakBoxUrl = (boxkey) => {
    return `https://mail.google.com/mail/u/0/#box/${boxkey}`
}


export default function SearchResult(props) {
    const classes = useStyles()
    const { company } = props
    const { name,
        description,
        url,
        focusTags,
        thesisSector,
        comments,
        funding,
        quality,
        stage,
        key: boxKey,
        score,
        pscouting,
        neighbors,
        competitors,
        info,
        } = company
    const streakUrl = createStreakBoxUrl(boxKey)
    const [modalOpen, setModalOpen] = React.useState(false);
    const {searchOptions, setSearchOptions} = useContext(SearchContext);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (modalOpen) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [modalOpen]);

    return (
        <Card
            variant="outlined"
            raised={true}>
            <CardContent>
                <InformationModal
                    open={modalOpen}
                    scroll="paper"
                    handleModalClose={handleModalClose}
                    comments={comments}
                    startupID={boxKey}
                    neighbors={neighbors}
                    pscouting={pscouting}
                    competitors={competitors}
                    info={info}
                />
                <Typography variant="h5"
                    className={classes.header}>
                    <Grid container justify="flex-start" alignItems="center" spacing={1}>
                        <Grid item> {name} </Grid>
                        {thesisSector &&
                            <Grid item>
                                <Chip label={thesisSector}
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    className={classes.thesisSector}
                                />
                            </Grid>}
                        <Grid item>
                            <Tooltip title="Visit Website" placement="top">
                                <Link href={url} onClick={redirectLink(url)}>
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        aria-label="Link to company website.">
                                        <LinkIcon></LinkIcon>
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        </Grid>
                        <Grid item>

                            <Tooltip title="Streak" placement="top">
                                <Link href={streakUrl} onClick={redirectLink(streakUrl)}>
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        aria-label="Link to company website.">
                                        <ViewCompactOutlinedIcon/>
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        </Grid>
                    </Grid>

                </Typography>
                <Grid container direction="row" justify='flex-start' spacing={1}>
                    {quality && <Grid item>
                        <Chip
                            color="primary"
                            style={qualityToStyle(quality)}
                            label={quality}
                            size='small' />
                    </Grid>}
                    {funding && <Grid item >
                        <Chip
                            label={funding}
                            size='small'
                            variant='outlined' />
                    </Grid>}
                </Grid>
                <Typography variant="body2" className={classes.supplementaryInfo} gutterBottom>

                    Stage: {stage}<br />
                    Score: {score}
                </Typography>
                <Typography variant="body1"
                    className={classes.description}>
                    {description ? description : <i>No Description.</i>}
                </Typography>
            </CardContent>
            <CardActions >
                <Grid container>
                    {focusTags.map((tag, i) => {
                        return (
                            <Chip style={{ backgroundColor: tag2color(tag) }}
                                className={classes.focusChip}
                                key={i}
                                label={tag}
                                color="primary"
                                size="small"
                                onClick={() => setSearchOptions({...searchOptions, searchPhrase: tag})}
                            />);
                    })}
                </Grid>
                <Tooltip title='More Information' placement='right'>
                    <IconButton
                        className={classes.moreInfo}
                        onClick={handleModalOpen}
                        aria-expanded={modalOpen}
                        aria-label="More Information"
                    >
                        <InfoOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </CardActions>
        </Card>
    );
}
