import React, {useState} from 'react';
import {useQuery, useQueryClient} from "react-query";
import {Dialog, DialogContent, DialogTitle, Typography, Chip, Box, Select, InputLabel, MenuItem, FormControl, Grid} from '@material-ui/core';
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {getFundraisingCsv, getFundraisingPlots} from "../apis/powersearch";
import Plot from "react-plotly.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from '@material-ui/lab/Alert';

const filterOptions= [{value: "Hardware/Software", label: "Hardware/Software"}, {value: "thesisSector", label: "Thesis Sector"},
    {value:"stage", label:"Stage"}, {value:"quality", label:"Quality Check"}, {value:"funding", label:"Funding Stage"}]

const dropNonFilterColumns = (startups) => {
    return startups.map(createObjectWithJustPermalinkAndFilters)

};
const createObjectWithJustPermalinkAndFilters = (startup) =>{
    const filteredStartup = {}
    for(let x in filterOptions){
        const filterOption= filterOptions[x]
        if(filterOption.value === "Hardware/Software"){
            filteredStartup[filterOption.value] = startup["info"]["hardwareSoftware"]
        }
        else{
            filteredStartup[filterOption.value] = startup[filterOption.value]
        }

    }
    filteredStartup["permalink"] = startup["permalink"]
    filteredStartup["url"] = startup["url"]
    return filteredStartup;

};

const downloadFile = ( data, fileName, fileType) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType })
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}

export default function FundingPlotsModal(props){



    const [open, setOpen] = useState(false);
    const [plotType, setPlotType] = useState('Rounds');
    const[filter, setFilter] = useState("Hardware/Software")
    const queryClient = useQueryClient();


    let startupsToSearch = undefined;
    if(props.data !== undefined){
        startupsToSearch = dropNonFilterColumns(props.data.filter(startup => startup.permalink !== "None"));

    }

    const{data, isLoading, isError, error} = useQuery(["plots", startupsToSearch, filter],()=> getFundraisingPlots(startupsToSearch, filter), {
        enabled: startupsToSearch !== undefined, staleTime:Infinity, onSuccess: () => {
            filterOptions.forEach(filterOption => {
                queryClient.prefetchQuery(["plots", startupsToSearch, filterOption.value], ()=>getFundraisingPlots(startupsToSearch, filterOption.value), {staleTime:Infinity});
            });
        }});


    const{data: fundraisingCsvData} = useQuery(["roundsCsv", startupsToSearch],()=> getFundraisingCsv(startupsToSearch), {
        enabled: startupsToSearch !== undefined, staleTime:Infinity});

    const handleChangePlotType = (event) => {
        setPlotType(event.target.value);
    };
    const handleChangeFilter = (event) => {
        setFilter(event.target.value);
    };


    if(!open){
        return <Chip label={"plot fundraising data"} onClick={()=> setOpen(true)}></Chip>
    }
    if(isError){
        // debugger;
        return <Alert severity="warning">
           Failed to fetch data {error.message}
        </Alert>
    }
    return (
        <Dialog
            open = {open}
            aria-labelledby="information-modal"
            aria-describedby="more-information-modal"
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle>
                <Typography variant="h6">
                    Fundraising plots
                </Typography>
                <Grid>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Plot Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={plotType}
                                label="Plot Type"
                                onChange={handleChangePlotType}
                            >
                                <MenuItem value={"Rounds"}>Rounds</MenuItem>
                                <MenuItem value={"Funding"}>Funding</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={filter}
                                label="Plot Type"
                                onChange={handleChangeFilter}
                            >
                                {filterOptions?.map(option => {
                                    return (
                                    <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                    </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Box>

                </Grid>

            </DialogTitle>

            <DialogContent>
                { isLoading &&
                    <Grid item xs={12}>
                        <CircularProgress color="secondary" />
                    </Grid>
                }
                {data!== undefined && data.rounds === undefined &&
                    <Alert severity="warning">
                        No startups with funding data
                    </Alert>

                }
                {data !==undefined && !isLoading && plotType === "Rounds" && data.rounds !== undefined &&
                    <Plot
                        data={data.rounds.cumulative.data}
                        layout={data.rounds.cumulative.layout}
                    />

                }
                {data !==undefined && !isLoading && plotType === "Rounds" && data.rounds !== undefined &&
                    <Plot
                        data={data.rounds.total.data}
                        layout={data.rounds.total.layout}
                    />
                }

                {data !== undefined && !isLoading && plotType === "Funding" && data.funding !== undefined &&
                <Plot
                    data={data.funding.cumulative.data}
                    layout={data.funding.cumulative.layout}
                />

                }
                {data !==undefined && !isLoading && plotType === "Funding" && data.funding !== undefined &&
                    <Plot
                    data={data.funding.total.data}
                    layout={data.funding.total.layout}
                    />
                }
                {data !==undefined && !isLoading &&
                <Typography >
                    {props.data.length- startupsToSearch.length}/{props.data.length} startups in current search don't have funding data
                </Typography>
                }


            </DialogContent>
            <DialogActions>
                <Button onClick={()=>downloadFile(fundraisingCsvData, "fundraising.csv", "csv")} color="secondary">
                    Export to csv
                </Button>
                <Button onClick={()=>setOpen(false)} color="secondary">
                    Close
                </Button>

            </DialogActions>
        </Dialog>
        )

}