import {team} from '../team.js';

export default function AvatarSrc(key) {
    let name = undefined
    if(key in team){
        name = team[key].name
    }
    if (name === undefined) {
        return {
            name: '',
            img: '',
        }
    }
    const first_char = name.charAt(0)
    const second_char = name.charAt(name.length - 1)
    return {
        name: first_char + second_char,
        img: team[key].img
    }
}
