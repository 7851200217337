import React, {useContext} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useState } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import {Chip, Grid, Box, Popover, Tooltip, Typography, MenuItem,
    Dialog, DialogContent, DialogTitle, IconButton,
    Input, InputLabel, FormControl, Select, FormControlLabel} from '@material-ui/core';
import IOSSwitch from "./iosSwitch";
import {getFilters} from '../apis/powersearch'
import {useQuery} from "react-query";
import FilterInstructions from "./instructionModals/filterInstructions";
import {SearchContext} from "../pages/searchv2";


const useStyles = makeStyles({
    root: {
        minWidth: "50%",
        minHeight: "50%",
    }
})
const useStyles2 = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 170,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default function FilterBox(props) {
    const {
      open, scroll, handleBoxClose
    } = props;


    const theme = useTheme();
    const {searchOptions, setSearchOptions} = useContext(SearchContext)
    const {filters} = searchOptions;
    const classes = useStyles()
    const classes2 = useStyles2()
    const {data, status} = useQuery("filterOptions", getFilters, {
        staleTime:Infinity
    })
    let filterOptions= {}
    if(status=="success"){
        data.focus.sort();
        filterOptions=data
    }
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [currentFilters, setCurrentFilters] = useState({
        stage: filters.stage.values,
        focusTags:filters.focusTags.values,
        quality: filters.quality.values,
        customer: filters.customer.values,
        hardware: filters.hardware.values,
        funding: filters.funding.values,
        diversity: filters.diversity.values,
        thesis: filters.thesis.values,
        partner: filters.partner.values
    })
    const [searchCommentState, setSearchCommentState] = useState(searchOptions.searchCommentState);

    const toggleComments = () => {
        setSearchCommentState((prev) => !prev);
    }

    const [focusToggle, setFocusToggle] = useState(filters.focusTags.type)
    const [customerToggle, setCustomerToggle] = useState(filters.customer.type)
    const [diversityToggle, setDiversityToggle] = useState(filters.diversity.type)
    const [partnerToggle, setPartnerToggle] = useState(filters.partner.type)

    const handleFocusToggle = () => {
       setFocusToggle((prev) => !prev);
     }

     const handleCustomerToggle = () => {
       setCustomerToggle((prev) => !prev);
     }

     const handleDiversityToggle = () => {
        setDiversityToggle((prev) => !prev);
     }

    const handlePartnerToggle = () => {
       setPartnerToggle((prev) => !prev);
     }
    const handleHelp = (event) => {
      setAnchorEl(event.currentTarget);
    }

    const handlePopoverClose = () => {
      setAnchorEl(null);
    }
    const openAnchor=Boolean(anchorEl)
    const id = openAnchor ? 'help-popover' : undefined;

    function handleChange(event)  {
      const value = event.target.value;
      setCurrentFilters({...currentFilters,
        [event.target.name]: value
        });
    };

    function deleteChip(name, value) {
      var array = [...currentFilters[name]]
      var index = array.indexOf(value)
      array.splice(index, 1)
     setCurrentFilters({...currentFilters,
        [name]: array
        });
    }

    function handleClose(event) {
        applyFilters()
        handleBoxClose();

    }

    function handleResetFilters(event) {
      setCurrentFilters({
        stage: [],
        focusTags: [],
        quality: [],
        customer: [],
        hardware: [],
        funding: [],
        diversity: [],
        thesis: [],
        partner: [],
      })
    }
    function applyFilters(){
        const currentSearchOptions = {...searchOptions}
        if(currentSearchOptions.searchPhrase===""){
            currentSearchOptions.allResults = 1
            currentSearchOptions.searchPhrase=  " "
        }
        currentSearchOptions.filters = {
            focusTags: {type: focusToggle, values: currentFilters.focusTags},
            customer: {type: customerToggle, values: currentFilters.customer},
            diversity: {type: diversityToggle, values:currentFilters.diversity},
            partner: {type: partnerToggle, values:currentFilters.partner},
            stage: {type: true, values:currentFilters.stage},
            quality: {type: true, values:currentFilters.quality},
            hardware: {type: true, values:currentFilters.hardware},
            funding: {type: true, values:currentFilters.funding},
            thesis: {type: true, values:currentFilters.thesis},
        }
        currentSearchOptions.searchCommentState = searchCommentState
        setSearchOptions(currentSearchOptions)

    }

    function handleApplyFilters(event) {
       applyFilters()
      handleBoxClose()
    }
    return (
        <Dialog
            open={open}
            scroll={scroll}
            onClose={handleClose}
            aria-labelledby="filter-modal"
            aria-describedby="filter-box-modal"
            className={classes.root}
            fullWidth={true}
            maxWidth={'md'}
        >

              <DialogTitle>
              <Grid container>
                <Box m={2}>
                  <Grid item alignItems='flex-start'>
                    <Typography variant="h6">
                        Filter By
                    </Typography>
                  </Grid>
                </Box>
                <Box m={2}>
                  <Grid item alignItems='flex-end'>
                      <Chip
                      label= "Apply Filters"
                      onClick={handleApplyFilters}
                      />
                  </Grid>
                </Box>
                <Box m={2} >
                  <Grid item alignItems='flex-end'>
                    <Chip
                      label = "Reset Filters"
                      onClick={handleResetFilters}
                      />
                  </Grid>
                </Box>

                <Box m={1.5} >
                  <Grid item alignItems='flex-end'>
                    <FormControlLabel
                    control={<IOSSwitch checked={searchCommentState} onChange={toggleComments} name="comment log toggle" />}
                    label="Search Comments & Logs"
                    />
                  </Grid>
                </Box>

                <Box m={1} >
                  <Grid item alignItems='flex-end'>
                    <Tooltip title = "Filter Box Guide" placement="right">
                      <IconButton onClick={handleHelp}>
                        <HelpIcon />
                      </IconButton>
                    </Tooltip>
                    <Popover
                      id={id}
                      open={openAnchor}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                        <FilterInstructions/>
                    </Popover>
                  </Grid>
                </Box>
              </Grid>
              </DialogTitle>




            <DialogContent>
            <Grid container spacing={3}>
                <MultiSelect name={"hardware"} currentFiltersValues={currentFilters.hardware} inputLabel={"Hardware/Software"} selectOptions={filterOptions.hardwareSoftware}/>
                <MultiSelectWithToggle name={"focusTags"} currentFiltersValues={currentFilters.focusTags} inputLabel={"Focus Tags"} selectOptions={filterOptions.focus} toggleState={focusToggle} handleToggle={handleFocusToggle}/>
                <MultiSelect name={"stage"} currentFiltersValues={currentFilters.stage} inputLabel={"Stage"} selectOptions={filterOptions.stage} />
                <MultiSelectWithToggle name={"customer"} currentFiltersValues={currentFilters.customer} inputLabel={"Customer Type"} selectOptions={filterOptions.customerType} toggleState={customerToggle} handleToggle={handleCustomerToggle} />
                <MultiSelect name={"quality"} currentFiltersValues={currentFilters.quality} inputLabel={"Quality Check"} selectOptions={filterOptions.qualityCheck} />
                <MultiSelectWithToggle name={"diversity"} currentFiltersValues={currentFilters.diversity} inputLabel={"Diversity Spotlight"} selectOptions={filterOptions.diversitySpotlight} toggleState={diversityToggle} handleToggle={handleDiversityToggle} />
                <MultiSelect name={"funding"} currentFiltersValues={currentFilters.funding} inputLabel={"Funding Status"} selectOptions={filterOptions.fundingStatus} />
                <MultiSelectWithToggle name={"partner"} currentFiltersValues={currentFilters.partner} inputLabel={"Partner Scouting"} selectOptions={filterOptions.partnerScouting} toggleState={partnerToggle} handleToggle={handlePartnerToggle} />
                <MultiSelect name={"thesis"} currentFiltersValues={currentFilters.thesis} inputLabel={"Thesis Sector"} selectOptions={filterOptions.thesisSector} />
            </Grid>
            </DialogContent>
        </Dialog>

    );
    function MultiSelect(props){
        return(
            <Grid item sm={4}>
                <MultiSelectForm name={props.name} currentFiltersValues={props.currentFiltersValues} inputLabel={props.inputLabel} selectOptions={props.selectOptions} />
            </Grid>
        )
    }
    function MultiSelectWithToggle(props){
        return(
        <React.Fragment>
            <Grid item sm={2.5}>
                <MultiSelectForm name={props.name} currentFiltersValues={props.currentFiltersValues} inputLabel={props.inputLabel} selectOptions={props.selectOptions} />
            </Grid>
            <Box mt={5} mr={1}>
                <Grid item sm={2.5}>
                    <FormControlLabel
                        control={<IOSSwitch checked={props.toggleState} onChange={props.handleToggle} name="focus tag toggle" />}
                        label="Filter as Any Of"
                    />
                </Grid>
            </Box>
        </React.Fragment>
        )
    }
    function MultiSelectForm (props){
        if(props.selectOptions !== undefined){
            return (
                <FormControl className={classes2.formControl}>
                    <InputLabel id="demo-mutiple-chip-label">{props.inputLabel}</InputLabel>
                    <Select
                        labelId="demo-mutiple-chip-label"
                        id="demo-mutiple-chip"
                        multiple
                        name={props.name}
                        value={props.currentFiltersValues}
                        onChange={handleChange}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                            <div className={classes2.chips}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} className={classes2.chip}
                                          onMouseDown={(event) => {
                                              event.stopPropagation();
                                          }} onDelete={() => deleteChip(props.name, value)}/>
                                ))}
                            </div>
                        )}
                    >
                        {props.selectOptions.map((tag) => (
                            <MenuItem key={tag} value={tag}>
                                {tag}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )
        }
    }
}


