import { createMuiTheme } from '@material-ui/core/styles';
import { blue, orange } from '@material-ui/core/colors'


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#687090',
    },
    secondary: {
      main: '#DF1864',
    },
  },
});

export default theme;